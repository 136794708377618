<script>
import "@vueform/multiselect/themes/default.css";
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import Swal from "sweetalert2";
import axios from 'axios';
import useGlobalData from "@/state/global-data";

export default {
  page: {
    title: "",
    meta: [{
      name: "description",
      content: appConfig.description
    }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    const {registrationLink} = useGlobalData();
    return {
      title: this.$t("t-menu-partners"),
      empty: false,
      items: [],
      page: 1,
      perPage: 8,
      pages: [],
      value: null,
      searchQuery: null,
      date: null,
      records: [],
      consentAccepted: false,
      consentType: null,
      registrationLink
    };
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.records);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
              data.email.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    records() {
      this.setPages();
    },
  },
  created() {
    this.setPages();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    copyRegistrationLink: function() {
      navigator.clipboard.writeText(this.registrationLink);
    },
    impersonate(data) {
      localStorage.setItem('impersonate-partner', data.id);
      window.location.href = '/';
    },
    retrievePartners() {
      axios.get(process.env.VUE_APP_API_BASE_URL+'/management/v1.0.0/partner/list').then((data) => {
        Swal.close();
        this.records = [];
        this.empty = (data.data.partners.length === 0)
        data.data.partners.forEach((row) => {
          this.records.push(row)
        })
        this.records.sort((a,b) => b.created - a.created)
      }).catch((er) => {
        Swal.close();
        console.log(er)
      });
    },
    formatEpoch(epoch) {
      const newDate = new Date(epoch*1000);
      return newDate.toLocaleString();
    },
    setPages() {
      let numberOfPages = Math.ceil(this.records.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(records) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return records.slice(from, to);
    },
  },
  mounted() {

  },
  beforeMount() {
    setTimeout(() => {
      Swal.fire({
        title: this.$t('t-loading'),
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      this.retrievePartners();
    }, 200);

  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header border-0">
            <div class="row g-4 align-items-center">
<!--              <div class="col-sm-3">-->
                <!--                <div class="search-box">-->
                <!--                  <input type="text" class="form-control search" placeholder="Search for..." />-->
                <!--                  <i class="ri-search-line search-icon"></i>-->
                <!--                </div>-->
<!--              </div>-->
              <div class="">
                <div class="hstack gap-2">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">{{ $t("t-partners-link") }}</span>
                    <input type="text" class="form-control text-center" id="inlineFormInputGroupUsername" :placeholder="registrationLink" disabled>
                    <div class="input-group-append">
                      <btn class="input-group-text btn btn-success" @click="copyRegistrationLink()"><i class="ri-file-copy-line text-white"></i></btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table class="table align-middle" id="customerTable">
                  <thead class="table-light">
                  <tr>
                    <th scope="col" style="width: 50px">
                    </th>

                    <th class="sort" data-sort="name">{{ $t("t-partners-name") }}</th>
                    <th class="sort" data-sort="type">{{ $t("t-partners-type") }}</th>
                    <th class="sort" data-sort="created">{{ $t("t-partners-added") }}</th>
<!--                    <th class="sort" data-sort="action"></th>-->
                  </tr>
                  </thead>
                  <tbody class="list form-check-all">
                  <tr v-if="!resultQuery.length">
                    <td></td>
                    <td colspan="5">{{ $t('t-partners-none') }}</td>
                  </tr>
                  <tr v-for="(data, index) of resultQuery" :key="index">
                    <th scope="row">
                    </th>
                    <td class="id" style="display: none">
                      <a href="javascript:void(0);" class="fw-medium link-primary">{{ data.id }}</a>
                    </td>
                    <td class="cursor-pointer" @click="impersonate(data)">
                      {{ data.name }}
                    </td>
                    <td class="type cursor-pointer" @click="impersonate(data)">{{ data.type }}</td>
                    <td class="created cursor-pointer" @click="impersonate(data)">{{ formatEpoch(data.created) }}</td>
<!--                    <td>-->
<!--                      <ul class="list-inline hstack gap-2 mb-0">-->
<!--                        <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"-->
<!--                            data-bs-placement="top" :title="$t('t-clients-impersonate')" @click="impersonate(data)">-->
<!--                          <a class="remove-item-btn" data-bs-toggle="modal">-->
<!--                            <i class="ri-user-2-fill align-bottom text-muted cursor-pointer"></i>-->
<!--                          </a>-->
<!--                        </li>-->
<!--                        <li class="list-inline-item" data-bs-toggle="modal" href="#showModal" data-bs-trigger="hover"-->
<!--                            data-bs-placement="top" title="Edit" @click="editdata(data)">-->
<!--                          <a class="edit-item-btn"><i class="ri-pencil-fill align-bottom text-muted cursor-pointer"></i></a>-->
<!--                        </li>-->
<!--                        <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover"-->
<!--                            data-bs-placement="top" title="Delete" @click="deletedata(data)">-->
<!--                          <a class="remove-item-btn" data-bs-toggle="modal">-->
<!--                            <i class="ri-delete-bin-fill align-bottom text-muted cursor-pointer"></i>-->
<!--                          </a>-->
<!--                        </li>-->

<!--                      </ul>-->
<!--                    </td>-->
                  </tr>
                  </tbody>
                </table>
                <div class="d-flex justify-content-end p-3">
                  <div class="pagination-wrap hstack gap-2">
                    <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                      Previous
                    </a>
                    <ul class="pagination listjs-pagination mb-0">
                      <li :class="{
                          active: pageNumber == page,
                          disabled: pageNumber == '...',
                        }" v-for="(pageNumber, index) in pages.slice(
                          page - 1,
                          page + 5
                        )" :key="index" @click="page = pageNumber">
                        <a class="page" href="#">{{ pageNumber }}</a>
                      </li>
                    </ul>
                    <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                      Next
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!--end col-->

    </div>

  </Layout>
</template>